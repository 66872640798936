.left-skew-45-x {
    transform: skewX(45deg);
}

.left-skew-45-y {
    transform: skewY(45deg);
}

.right-skew-45-x {
    transform: skewX(-45deg);
}

.right-skew-45-y {
    transform: skewY(-45deg);
}

.skew-parent-45 {
    transform: skew(45deg);
}

.skew-child-45 {
    transform: skew(-45deg);
}