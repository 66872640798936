body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*imports for general css only*/

@import url("./common/index.css");
@import url("./variables/index.css");


@media (min-width: 600px) {
  .class-name-to-show-mobile-hide-desktop {
    display: none;
  }
}
@media (max-width: 600px) {
  .class-name-to-show-desktop-hide-mobile {
    display: none;
  }
}

