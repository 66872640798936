/*  BACKGROUND COLORS  */

.bg-orange {
    background: var(--orange);
}

.bg-light-orange {
    background: var(--light-orange);
}

.bg-dark-green {
    background: var(--dark-green);
}

.bg-dark-blue {
    background: var(--dark-blue);
}