.flex {
    display: flex;
}

.direction-column {
    flex-direction: column;
}

.direction-row {
    flex-direction: row;
}

.justify-start {
    justify-content: start;
}

.justify-end {
    justify-content: end;
}

.justify-center {
    justify-content: center;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-space-evenly {
    justify-content: space-evenly;
}