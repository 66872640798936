/*  LINE HEIGHT  */
.lh-general {
    line-height: 1.2;
}

/*  FONT WEIGHT  */
.fw700 {
    font-weight: 700;
}

/*  FONT SIZE  */

.fs10 {
    font-size: 10px;
}

.fs12 {
    font-size: 12px;
}

.fs16 {
    font-size: 16px;
}

.fs20 {
    font-size: 20px;
}

.fs24 {
    font-size: 24px;
}

.fs28 {
    font-size: 28px;
}

.fs30 {
    font-size: 30px;
}

.fs32 {
    font-size: 32px;
}

.fs42 {
    font-size: 42px;
}