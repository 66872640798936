/*  OVERFLOWS  */
.overflow-hidden {
    overflow: hidden;
}

/*  CURSORS  */
.pointer {
    cursor: pointer;
}

/*  BORDERS  */


/*  BORDER RADIUS  */
.br5 {
    border-radius: 5px;
}

.br10 {
    border-radius: 10px;
}

.br20 {
    border-radius: 20px;
}

.br50 {
    border-radius: 50px;
}

.btl20 {
    border-top-left-radius: 20px;
}

.btr20 {
    border-top-right-radius: 20px;
}

/*  Z INDEX  */

.z1 {
    z-index: 1;
}

.z5 {
    z-index: 5;
}

/*  POSITIONING  */

.absolute {
    position: absolute;
}

.t-10 {
    top: -10px;
}

.r-10 {
    right: -10px;
}

.b-10 {
    bottom: -10px;
}

.l-10 {
    left: -10px;
}

.t0 {
    top: 0;
}

.r0 {
    right: 0;
}

.b0 {
    bottom: 0;
}

.l0 {
    left: 0;
}

.t4 {
    top: 4px;
}

.r4 {
    right: 4px;
}

.b4 {
    bottom: 4px;
}

.l4 {
    left: 4px;
}

.t50 {
    top: 50px;
}

.r50 {
    right: 50px;
}

.b50 {
    bottom: 50px;
}

.l50 {
    left: 50px;
}