/* PERCENTAGE */

.wp100 {
    width: 100%;
}

.hp100 {
    height: 100%;
}

.wp90 {
    width: 90%;
}

.hp90 {
    height: 90%;
}

.wp85 {
    width: 85%;
}

.hp85 {
    height: 85%;
}

.wp45 {
    width: 45%;
}

.hp45 {
    height: 45%;
}

.wp65 {
    width: 65%;
}

.hp65 {
    height: 65%;
}

/* PIXELS */

.w60 {
    width: 60px;
}

.h60 {
    height: 60px;
}

.h80{
    height: 80px;
}
.h120 {
    height: 120px;
}

.w100 {
    width: 100px;
}

.h100 {
    height: 100px;
}

.w130 {
    width: 130px;
}

.h130 {
    height: 130px;
}

.w150 {
    width: 150px;
}

.h150 {
    height: 150px;
}

.w200 {
    width: 200px;
}

.h200 {
    height: 200px;
}
