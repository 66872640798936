/*  NEGATIVE MARGINS  */

.neg-mt20 {
    margin-top: -20px;
}

.neg-mb20 {
    margin-bottom: -20px;
}

.neg-ml20 {
    margin-left: -20px;
}

.neg-mr20 {
    margin-right: -20px;
}

.neg-mt30 {
    margin-top: -30px;
}

.neg-mb30 {
    margin-bottom: -30px;
}

.neg-ml30 {
    margin-left: -30px;
}

.neg-mr30 {
    margin-right: -30px;
}

.neg-mt45 {
    margin-top: -45px;
}

.neg-mb45 {
    margin-bottom: -45px;
}

.neg-ml45 {
    margin-left: -45px;
}

.neg-mr45 {
    margin-right: -45px;
}

.neg-mt100 {
    margin-top: -100px;
}

.neg-mb100 {
    margin-bottom: -100px;
}

.neg-ml100 {
    margin-left: -100px;
}

.neg-mr100 {
    margin-right: -100px;
}